/* Animations */
p {
  animation: .45s ease-out 0s 1 fadeSlideIn;
}

button {
  animation: .25s ease-out 0s 1 fadeSlideIn;
}

img {
  border-radius: 6px;
  animation-name: fadeIn;
  animation-duration: .75s;
  animation-timing-function: ease-in;
}

.fadeIn {
  animation: 0.5s ease-out 0s 1 fadeIn;
}

.fadeSlideIn {
  animation: 0.5s ease-out 0s 1 fadeSlideIn;
}

.hover-underline-animation {
  display: inline-block;
  position: relative;
}

.hover-underline-animation:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: var(--secondary);
  transform-origin: bottom left;
  transition: transform 0.4s ease-out;
}

.hover-underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeSlideIn {
  from {
    opacity: 0;
    transform: translateY(20%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes main-color {
  to {
    color: var(--primary);
  }
}

@keyframes secondary {
  to {
    color: var(--secondary);
  }
}

@keyframes secondary-background {
  to {
    background: var(--secondary);
  }
}
