:root {
  --primary: #681577;
  --background: #fafafa;
  --light-background: #f4f4f4;
  --text-color: #272727;
  scrollbar-width: none;
}

.primary {
  color: var(--primary);
}

.lightgray {
  color: lightgray;
}

.darkgrey {
  color: darkgrey;
}

.danger {
  color: red;
}
